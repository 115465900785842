<template>
    <div class="user_photo">
        <!-- Loader overlay with improved visibility -->
        <v-progress-circular 
            v-if="changeAvatarLoader" 
            :size="160" 
            :width="3" 
            style="z-index: 1;" 
            color="#20C4F5" 
            indeterminate
        ></v-progress-circular>

        <!-- Skeleton loader with combined conditions -->
        <div 
            v-if="isLoading || (!avatar.loaded && !isImageDefault)" 
            class="skeleton-loader"
        ></div>
        
        <!-- Avatar image with optimized loading -->
        <v-img 
            :key="avatar.key"
            :src="`${avatar.small}?cache=${avatar.key}`"
            :srcset="avatar.srcset ? `${avatar.small}?cache=${avatar.key} 1x, ${avatar.srcset}?cache=${avatar.key} 2x` : null"
            :aspect-ratio="1"
            :eager="true"
            @load="avatarLoaded"
            :class="{
                'default-image': isImageDefault, 
                'show': avatar.loaded || isImageDefault,
                'hidden': isLoading || (!avatar.loaded && !isImageDefault)
            }"
        
            @click="$emit('show-modal')"
            alt="User profile avatar"
        ></v-img>
    </div>
</template>

<script>
export default {
    name: "UserProfileAvatar",
    components: {},
    props: {
        userLogo: {
            type: Object,
            default: () => ({})
        },
        isLoading: Boolean,
        routeParams: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isImageDefault: true,
            avatar: {
                original: '',
                small: '',
                srcset: '',
                loaded: false,
                key: new Date().getTime(),
            },
            changeAvatarLoader: false,
        }
    },
    watch: {
        userLogo: {
            handler(newVal) {
                this.changeAvatarLoader = true;

                this.$nextTick(() => {
                    if (newVal && newVal['240x270'] && typeof newVal['240x270'] === 'string') {
                        // Set custom avatar
                        this.avatar = {
                            small: newVal['240x270'],
                            srcset: newVal['240x270@2x'] || '',
                            original: newVal['960x1080'] || newVal['240x270'],
                            loaded: false,
                            key: new Date().getTime()
                        };
                        this.isImageDefault = false;
                    } else {
                        // Reset to default avatar using the new URL
                        this.avatar = {
                            small: 'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/square_avatar_animated.svg',
                            original: 'https://psy.nyc3.cdn.digitaloceanspaces.com/public/images/square_avatar_animated.svg',
                            srcset: '',
                            loaded: true,
                            key: new Date().getTime()
                        };
                        this.isImageDefault = true;
                    }

                    this.$emit('avatar-updated', {
                        isDefault: this.isImageDefault,
                        smallUrl: this.avatar.small,
                        srcset: this.avatar.srcset,
                        original: this.avatar.original,
                        key: this.avatar.key
                    });

                    this.changeAvatarLoader = false;
                });
            },
            deep: true,
            immediate: true
        },

        isLoading: {
            handler(newVal) {
                if (!newVal && this.avatar.loaded) {
                    this.avatarLoaded();
                }
            },
            immediate: true
        }
    },
    methods: {
        avatarLoaded() {
            this.avatar.loaded = true;
            this.$emit('avatar-updated', {
                isDefault: this.isImageDefault,
                smallUrl: this.avatar.small,
                srcset: this.avatar.srcset,
                original: this.avatar.original,
                key: new Date().getTime()
            });
        },
        updateCacheKey(newKey) {
            this.avatar.key = newKey;
            this.$emit('update-cache-key', newKey);
            this.$emit('avatar-updated', {
                isDefault: this.isImageDefault,
                smallUrl: this.avatar.small,
                srcset: this.avatar.srcset,
                original: this.avatar.original,
                key: newKey
            });
        }
    }
}
</script>

<style lang="scss" scoped>
// .user_photo {
//     position: relative;
//     width: 240px;
//     height: 270px;
//     border-radius: 10px;
//     overflow: hidden;
//     cursor: pointer;
//     background-color: #10182B;


//     // Progress circular overlay
//     .v-progress-circular {
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%, -50%);
//         z-index: 2;
//     }

//     // Image styling
//     .v-image {
//         width: 100%;
//         height: 100%;
//         transition: opacity 0.3s ease;

//         &.hidden {
//             opacity: 0;
//         }

//         &.show {
//             opacity: 1;
//         }

//         &.default-image {
//             background-color: #10182B;
//         }
//     }
// }


</style>