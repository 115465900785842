export default {
    computed: {
        showActionSkeletons() {
            return this.loader || this.actionButtonsLoader;
        }
    },
    components: {
        renderActionButtonSkeletons: {
            functional: true,
            render(h, context) {
                if (!context.parent.showActionSkeletons) return null;
                
                return h('div', {
                    style: {
                        display: 'flex',
                        gap: '8px'
                    }
                }, [
                    h('div', {
                        class: 'skeleton-loader action-button-skeleton',
                        key: 'loading-skeleton-1'
                    }),
                    h('div', {
                        class: 'skeleton-loader action-button-skeleton',
                        key: 'loading-skeleton-2'
                    }),
                    h('div', {
                        class: 'skeleton-loader action-button-skeleton',
                        key: 'loading-skeleton-3'
                    })
                ]);
            }
        }
    }
} 